import { mapGetters } from 'vuex'

export const CONTENT_BLOCK_TYPE_ARTICLE = 'article'
export const CONTENT_BLOCK_TYPE_VIDEO = 'video'
export const CONTENT_BLOCK_TYPE_THEME = 'theme'
export const CONTENT_BLOCK_TYPE_SPECIALS = 'specialArticleGroup'
export const CONTENT_BLOCK_TYPE_SERIES = 'series'

export default {
  computed: {
    ...mapGetters(['vlm']),
    contentBlockTypes () {
      return {
        article: CONTENT_BLOCK_TYPE_ARTICLE,
        video: CONTENT_BLOCK_TYPE_VIDEO
      }
    },
    contentBlockTypeValues () {
      if (this.vlm) {
        return [
          {
            id: CONTENT_BLOCK_TYPE_ARTICLE,
            title: this.$t('contentBlock.content_block_type_value.article')
          },
          {
            id: CONTENT_BLOCK_TYPE_THEME,
            title: this.$t('contentBlock.content_block_type_value.topics')
          },
          {
            id: CONTENT_BLOCK_TYPE_SPECIALS,
            title: this.$t('contentBlock.content_block_type_value.specials')
          },
          {
            id: CONTENT_BLOCK_TYPE_SERIES,
            title: this.$t('contentBlock.content_block_type_value.serials')
          }
        ]
      }
      return [
        {
          id: CONTENT_BLOCK_TYPE_ARTICLE,
          title: this.$t('contentBlock.content_block_type_value.article')
        },
        {
          id: CONTENT_BLOCK_TYPE_VIDEO,
          title: this.$t('contentBlock.content_block_type_value.video')
        }
      ]
    }
  }
}
