const messages = {
  copy: {
    first: 'HP block',
    last: 'was copied'
  },
  list: {
    id: 'Id',
    type: 'Type',
    title: 'Title',
    identifier: 'Identifier',
    number_of_items: 'Number of items',
    external_fallback: 'External',
    internal_fallback: 'Article',
    fallback_article_types: 'Fallback article types',
    fallback_article_flags: 'Fallback article flags',
    hidden: 'Hidden',
    position: 'Position',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by'
  },
  filter: {
    site: 'Site',
    type: 'Type',
    title: 'Title',
    identifier: 'Identifier',
    id: 'Id',
    search: 'Search'
  },
  id: 'Id',
  hidden: 'Hidden',
  type: 'Type',
  title: 'Title',
  identifier: 'ID',
  site: 'Site',
  sites_and_subsites: 'Sites/Subsites',
  category: 'Category',
  number_of_items: 'Number of items',
  position: 'Position',
  daysLimit: 'Query limit days',
  display_position: 'Display position',
  disabled_positions: 'Disabled positions',
  fallback_positions: 'Fallback positions',
  external_fallback: 'Enable external fallback',
  internal_fallback: 'Enable internal fallback',
  fallback_site: 'Site',
  fallback_rubrics: 'Rubrics',
  fallback_tags: 'Redactor Tags',
  geneea_tags: 'Geneea tags',
  fallback_article_types: 'Article types (logical OR will be apploed)',
  fallback_article_flags: 'Article\'s Flags (logical OR will be applied)',
  fallback_article_settings: 'Other',
  fallback_categories: 'Categories',
  source: 'Source',
  content: 'Content',
  content_block_type_value: {
    article: 'Articles',
    video_article: 'Video article',
    video: 'Video',
    topics: 'Topics',
    specials: 'Specials',
    serials: 'Serials'
  },
  main_settings: 'Main settings',
  fallback_settings: 'Content settings',
  gallery: 'Gallery',
  pr: 'Only PR articles',
  items: 'Items',
  total: 'Total',
  regional_min: 'Regional min',
  sport_max: 'Sport max',
  author: 'Author',
  internal: 'Internal',
  external: 'External',
  flags: 'Flags',
  isHomepage: 'Homepage',
  article: 'Article',
  cbArticleType: 'Content Block Type',
  buttonName: 'Button name',
  buttonUrl: 'Button URL',
  contentBlockFlags: {
    video: 'Video',
    imageGallery: 'Photo Gallery',
    podcast: 'Podcast',
    quiz: 'Quiz',
    poll: 'Poll',
    discussion: 'Discussion'
  }
}

export default messages
